import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';
import rehypeHighlight from 'rehype-highlight';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import waxaiHeader from './waxai_header.png';

function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsPanelOpen(false);
      } else {
        setIsPanelOpen(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSend = async (customMessage = null) => {
    if (isSending) return;

    setIsSending(true);
    const messageText = customMessage || input;
    const newMessage = { user: 'me', text: messageText };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);
    setInput('');

    try {
      const response = await axios.post('/api/message', { message: messageText, chatHistory: updatedMessages });
      const aiResponseText = typeof response.data.response === 'string' ? response.data.response : JSON.stringify(response.data.response, null, 2);
      setMessages([...updatedMessages, { user: 'ai', text: aiResponseText }]);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend();
    }
  };

  const handleChange = (e) => {
    setInput(e.target.value);
    adjustTextareaHeight(e.target);
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 6 * parseFloat(getComputedStyle(textarea).lineHeight))}px`;
  };

  const calculatePadding = (text) => {
    const lineCount = (text.match(/\n/g) || []).length + 1;
    const isShortMessage = text.length < 25;
    let verticalPadding;
    let horizontalPadding;

    if (isShortMessage) {
      verticalPadding = '1px';
      horizontalPadding = `7px`;
    } else {
      verticalPadding = lineCount > 3 ? '15px' : '10px';
      horizontalPadding = `17px`;
    }

    return `${verticalPadding} ${horizontalPadding}`;
  };

  const sendAutoMessage = async () => {
    try {
      const initialMessage = 'Hey';
      const chatHistory = [];
      const response = await axios.post('/api/message', { message: initialMessage, chatHistory });
      setMessages([{ user: 'ai', text: response.data.response }]);
    } catch (error) {
      console.error('Error sending initial message:', error);
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      adjustTextareaHeight(textarea);
    }
  }, [input]);

  useEffect(() => {
    const timer = setTimeout(sendAutoMessage, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleQuickAction = (action) => {
    if (action === 'getTokens') {
      handleSend('Get Tokens');
    } else if (action === 'getAccountDetails') {
      handleSend('Get Account Details');
    } else if (action === 'getTotalAssets') {
      handleSend('Get Total Assets');
    }
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  // Utility function to extract text content from React nodes
  const extractText = (node) => {
    if (typeof node === 'string') {
      return node;
    }
    if (Array.isArray(node)) {
      return node.map(extractText).join('');
    }
    if (node.props && node.props.children) {
      return extractText(node.props.children);
    }
    return '';
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header-content">
            <img src={waxaiHeader} alt="WAX AI Header" className="header-image" />
          </div>
        </header>
        <Routes>
          <Route path="/" element={
            <div className="main-layout">
              <div className={`quick-actions-panel ${isPanelOpen ? 'open' : 'closed'}`}>
                <div className="panel-header">
                  <span>Quick Actions</span>
                  <div className="info-icon" title="These Quick Actions let you query realtime data on chain!">ℹ️</div>
                </div>
                <button onClick={togglePanel} className="toggle-button">
                  {isPanelOpen ? '◀' : '▶'}
                </button>
                {isPanelOpen && (
                  <div className="quick-actions">
                    <button onClick={() => handleQuickAction('getTokens')} className="quick-action-button">Get Tokens</button>
                    <button onClick={() => handleQuickAction('getAccountDetails')} className="quick-action-button">Get Account Details</button>
                    <button onClick={() => handleQuickAction('getTotalAssets')} className="quick-action-button">Get Total Assets</button>
                  </div>
                )}
              </div>
              <div className={`chat-container ${isPanelOpen ? '' : 'expanded'}`}>
                <div className="messages">
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={msg.user === 'me' ? 'user-message' : 'ai-message'}
                      style={{ padding: calculatePadding(msg.text) }}
                    >
                      {msg.user === 'ai' && <img src="/waxdapps.png" alt="Assistant Logo" className="assistant-logo" />}
                      <ReactMarkdown
                        children={msg.text}
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeSanitize, rehypeHighlight]}
                        components={{
                          a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" />,
                          code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '');
                            const codeText = extractText(children);
                            return !inline && match ? (
                              <div className="code-block">
                                <div className="code-block-header">
                                  <span className="code-language">{match[1]}</span>
                                  <CopyToClipboard text={codeText}>
                                    <button className="copy-button">Copy</button>
                                  </CopyToClipboard>
                                </div>
                                <pre className={`language-${match[1]}`} {...props}>
                                  <code className={className} style={{ whiteSpace: 'pre-wrap' }}>
                                    {children}
                                  </code>
                                </pre>
                              </div>
                            ) : (
                              <code className={className} {...props} style={{ whiteSpace: 'pre-wrap' }}>
                                {children}
                              </code>
                            );
                          },
                        }}
                      />
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
                <div className="input-container">
                  <textarea
                    ref={textareaRef}
                    value={input}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Message WAX AI"
                    rows="1"
                    style={{ height: '2em' }}
                  />
                  {isSending ? (
                    <button disabled={true} className="square-button">
                      <span className="square-icon">◼️</span>
                    </button>
                  ) : (
                    <button onClick={handleSend} className="arrow-up-button" disabled={input.trim() === '' || isSending}>
                      <span className="arrow-up-icon">⬆</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
